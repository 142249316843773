import i18n from 'utils/i18n';
import React from 'react';
import loadable from 'react-loadable';
import IvhLoading from 'components/common/Loading';
const { t } = i18n;
interface Node {
  page: string;
  title: string;
  children?: Array<Node>;
  activeNav?: string;
  preload?: boolean;
  path?: string;
}
interface Router {
  component:
    | (React.ComponentClass<unknown, any> & loadable.LoadableComponent)
    | (React.FunctionComponent<unknown> & loadable.LoadableComponent);
  path: Array<string>;
  page: string;
}

const menus: Array<Node> = [
  { title: t('首页'), page: 'home', activeNav: 'home' },
  // { title: t('我的项目'), page: 'index' },
  { title: t('形象设置'), page: 'image_configure', activeNav: 'application' },
  { title: t('形象设置'), page: 'image_set', activeNav: 'application' },
  { title: t('机器人设置'), page: 'robot_config', activeNav: 'application' },
  { title: t('动作组配置'), page: 'action_list', activeNav: 'application' },
  { title: t('话术管理'), page: 'sentence_list', activeNav: 'application', preload: true },
  { title: t('互动调优'), page: 'interact_tuning', activeNav: 'application' },
  { title: t('动作预览'), page: 'action_preview_list', activeNav: 'application' },
  { title: t('API接入'), page: 'api_detail', activeNav: 'application', preload: true },
  {
    title: t('文本对话管理'),
    page: 'customer_page',
    activeNav: 'application',
    preload: true,
    path: '/customer_page/products/:product/:user_id/:module/*',
  },
  { title: t('选择应用场景'), page: 'entry_choose_page' },
  // { title: t('我的项目'), page: 'scene_index' },
  { title: t('小程序插件接入'), page: 'config_upgrade', activeNav: 'application', preload: true },
  { title: t('SDK接入'), page: 'sdk_insert', activeNav: 'application', preload: true },
  { title: t('SDK接入'), page: 'sdk_insert_api', activeNav: 'application', preload: true },
  { title: t('H5接入'), page: 'h5_insert', activeNav: 'application', preload: true },
  { title: t('数智人相册'), page: 'image_photo', activeNav: 'image' },
  { title: t('我的数智人'), page: 'image_template', activeNav: 'image' },
  { title: t('小样本定制'), page: 'custom_sample', activeNav: 'image' },
  { title: t('小样本定制'), page: 'custom_sample_photo', activeNav: 'image' },
  { title: t('小样本定制'), page: 'custom_sample_voice', activeNav: 'image' },
  { title: t('小样本定制'), page: 'custom_sample_multi_voice', activeNav: 'image' },
  { title: t('创建自定义形象'), page: 'create_image_template', activeNav: 'image' },
  { title: t('场景应用'), page: 'application', activeNav: 'application' },
  { title: t('数智人定制'), page: 'image_customization', activeNav: 'image' },
  { title: t('播报页面'), page: 'bvh_page', activeNav: 'application' },
  { title: t('表情预览'), page: 'expression_preview', activeNav: 'application' },
  { title: t('运营管理分析'), page: 'analysis', activeNav: 'analysis' },
  { title: t('定制资产管理'), page: 'image_customization_manager', activeNav: 'image' },
  { title: t('定制资产训练效果确认'), page: 'image_customization_result', activeNav: 'image' },
  { title: t('资产管理'), page: 'asserts_management', activeNav: '' },
  {
    title: t('对话管理'),
    page: 'lke_page',
    activeNav: 'application',
    preload: true,
    path: '/lke/:page/:module/*',
  },
  {
    title: t('空白页面'),
    page: 'empty_page',
    activeNav: '',
  },
  { title: t('音色复刻录制任务管理'), page: 'timbre_replication_management', activeNav: '' },
  { title: t('小样本定制素材预检测'), page: 'precheck', activeNav: 'image' },
];
// 有新页面在上面添加路径和页面路由

function Loading(props: any) {
  if (props.pastDelay) {
    return <IvhLoading />;
  }
  return null;
}

function wrapComponent(page: string) {
  return loadable({
    loader: () => import(/* webpackExclude: /components/ */ `./pages/${page}/index.tsx`),
    loading: Loading,
  });
}

const routers: Array<Router> = [];

function node(nodes: Array<Node>, parentTitles: Array<string> = []) {
  nodes.forEach(({ page, title, children, preload, path }) => {
    if (page) {
      const component = wrapComponent(page);
      // 解决微前端引入客服
      if (!!preload) component.preload();
      routers.push({
        component,
        path: [...parentTitles, title],
        page: path || `/${page}`,
      });
    } else if (children) {
      node(children, [...parentTitles, title]);
    }
  });
}
node(menus);

export default routers;
export { menus };
